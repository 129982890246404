<template>
    <v-card outlined>
        <v-card-text>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" class="justify-center align-center">
                    <v-data-table multi-sort dense :headers="headers" :items="itemsCompra" :items-per-page="10" class="elevation-1">
                        <template v-slot:[`item.colaborador`]="{ item }">
                            {{ item.idColaborador || 'No asignado' }}
                        </template>

                        <template v-slot:[`item.cambios`]="{ item }">
                            <div v-for="(change, key) in item.changes_json" :key="key">
                                <strong>{{ key }}:</strong> {{ change.before }} → {{ change.after }}
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.common.js'

export default {
    data() {
        return {
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'COLABORADOR', value: 'colaborador' },
                { text: 'CAMPOS MODIFICADOS', value: 'idUsuario' }
            ]
        }
    },
    computed: {
        ...mapState('ventas', ['datos']),
        ...mapGetters('ventas', ['ticketChangeHistory']),
        itemsCompra() {
            return this.ticketChangeHistory || []
        }
    },

    async mounted() {
        await this.$store.dispatch('ventas/getTicketChangeHistory', this.datos.id)
    }
}
</script>
